<template>
  <div>
    <q-form ref="editForm">
      <c-card class="cardClassDetailForm" title="인허가 요청정보">
        <template slot="card-button">
          <q-btn-group outline >
              <c-btn
              v-show="editable && Boolean(param.limLicenseRequestId) && deleteEditable && !disabled"
              label="삭제"
              icon="delete_forever"
              @btnClicked="deleteData"/>
              <c-btn
              v-show="editable && !disabled"
              :url="saveUrl"
              :isSubmit="isSave"
              :param="licenseRequest"
              :mappingType="mappingType"
              label="저장"
              icon="save"
              @beforeAction="saveData"
              @btnCallback="saveCallback" />
            <c-btn
              v-show="editable && Boolean(param.limLicenseRequestId) && !disabled"
              :url="receiptUrl"
              :isSubmit="isRequest"
              :param="licenseRequest"
              mappingType="PUT"
              label="인허가요청"
              icon="check"
              @beforeAction="requestData"
              @btnCallback="requestCallback" />
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
            <c-text
              :required="true"
              :disabled="disabled"
              :editable="editable"
              label="인허가 요청명"
              name="licenseRequestName"
              v-model="licenseRequest.licenseRequestName">
            </c-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <c-plant
              :required="true"
              :disabled="disabled"
              :editable="editable"
              type="edit"
              name="plantCd"
              v-model="licenseRequest.plantCd">
            </c-plant>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <c-field
              :editable="editable"
              :disabled="true"
              :data="licenseRequest"
              deptValue="requestDeptCd"
              type="dept_user"
              label="요청부서 & 요청자"
              name="requestUserId"
              v-model="licenseRequest.requestUserId">
            </c-field>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <c-field
              :required="true"
              :disabled="disabled"
              :editable="editable"
              :data="licenseRequest"
              deptValue="reviewDeptCd"
              type="dept_user"
              label="검토부서 & 검토자"
              name="reviewUserId"
              v-model="licenseRequest.reviewUserId">
            </c-field>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <c-datepicker
              :required="true"
              :disabled="disabled"
              :editable="editable"
              label="인허가 완료 요청일"
              name="completeRequestDate"
              v-model="licenseRequest.completeRequestDate">
            </c-datepicker>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <c-textarea
              :disabled="disabled"
              :editable="editable"
              :rows="2"
              name="requestContent"
              label="요청 내용"
              v-model="licenseRequest.requestContent" />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <c-textarea
              v-show="licenseRequest.requestStepCd == 'RSC0999999'"
              :disabled="true"
              :editable="editable"
              label="기각사유"
              name="dismissReason"
              v-model="licenseRequest.dismissReason" />
          </div>
        </template>
      </c-card>
    </q-form>
    <c-table
      ref="table"
      class="q-mt-sm"
      title="인허가 검토 목록"
      :columns="grid.columns"
      :data="licenseRequest.reviews"
      :gridHeight="grid.height"
      :columnSetting="false"
      :isFullScreen="false"
      :usePaging="false"
      :isExcelDown="false"
      :editable="editable&&!disabled"
      selection="multiple"
      rowKey="limLicenseReviewId"
    >
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable&&!disabled" label="추가" icon="add" @btnClicked="addReview" />
          <c-btn v-if="editable&&!disabled&&licenseRequest.reviews.length>0" label="제외" icon="remove" @btnClicked="deleteReview" />
        </q-btn-group>
      </template>
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name==='limLicenseKindId'">
          <c-license-kind
            :required="true"
            :editable="editable && !disabled"
            :data="props.row"
            relatedLawsName="relatedLawsName"
            label=""
            name="limLicenseKindId"
            v-model="props.row.limLicenseKindId"
            @dataChange="val => dataChange(props)">
          </c-license-kind>
        </template>
        <template v-else-if="col.name==='asLicenseSeq'">
          <c-license
            v-if="props.row.renewalFlag == 'Y'"
            :required="true"
            :editable="editable && !disabled"
            :data="props.row"
            :searchLimLicenseKindId="props.row.limLicenseKindId"
            relatedLawsName="relatedLawsName"
            limLicenseKindId="limLicenseKindId"
            limLicenseKindName="asLicenseSeqName"
            label=""
            name="asLicenseSeq"
            v-model="props.row.asLicenseSeq"
            @dataChange="val => dataChange(props)">
          </c-license>
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: "license-request-detail",
  props: {
    param: {
      type: Object,
      default: () => ({
        limLicenseRequestId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      licenseRequest: {
        limLicenseRequestId: '',  // 인허가 요청 일련번호
        plantCd: '',  // 사업장 코드
        licenseRequestName: '',  // 인허가 요청명
        requestDeptCd: '',  // 요청부서 코드
        requestUserId: '',  // 요청자
        completeRequestDate: '',  // 인허가 완료 요청일
        requestContent: '',  // 요청 내용
        sopMocId: '',  // MOC 일련번호
        requestStepCd: 'RSC0000001',  // 요청및검토 진행 단계 : RSC0000002(요청),RSC0000003(검토 및 진행중),RSC0999999(기각),RSC1000000(완료)
        dismissReason: '',  // 기각사유
        reviewOpinion: '',  // 검토 종합 의견
        reviewUserId: '',  // 종합 검토자
        reviewDeptCd: '',  // 검토 부서
        regUserId: '',  // 등록자
        chgUserId: '',  // 수정자
        reviews: [],
        deleteReviews: [],
      },
      grid: {
        columns: [
          {
            name: 'relatedLawsName',
            field: 'relatedLawsName',
            label: '관련법규',
            align: 'center',
            style: 'widht: 150px',
            sortable: false,
          },
          {
            required: true,
            name: 'limLicenseKindId',
            field: 'limLicenseKindId',
            label: '인허가종류',
            align: 'center',
            style: 'widht: 150px',
            type: 'custom',
            sortable: false,
          },
          {
            name: 'review',
            field: 'review',
            label: '검토',
            sortable: false,
            child: [
              {
                name: 'reviewDeptName',
                field: 'reviewDeptName',
                label: '부서',
                align: 'center',
                style: 'widht: 120px',
                sortable: false,
              },
              {
                required: true,
                name: 'reviewUserName',
                field: 'reviewUserName',
                label: '검토자',
                align: 'center',
                style: 'widht: 100px',
                type: 'user',
                userId: 'reviewUserId',
                deptName: 'reviewDeptName',
                sortable: false,
              },
            ]
          },
          {
            name: 'renewalFlag',
            field: 'renewalFlag',
            label: '갱신여부',
            align: 'center',
            style: 'widht: 80px',
            type: 'check',
            true: 'Y',
            false: 'N',
            sortable: false,
          },
          {
            name: 'asLicenseSeq',
            field: 'asLicenseSeq',
            label: '갱신대상 인허가',
            align: 'center',
            style: 'widht: 180px',
            type: 'custom',
            sortable: false,
          },
        ],
        height: '500px'
      },
      editable: true,
      isSave: false,
      isRequest: false,
      detailUrl: '',
      saveUrl: '',
      deleteUrl: '',
      receiptUrl: '',
      mappingType: 'POST',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
    };
  },
  computed: {
    deleteEditable() {
      return this.licenseRequest.requestStepCd == 'RSC0000001' 
        || this.licenseRequest.requestStepCd == 'RSC0000002'
        || this.licenseRequest.requestStepCd == 'RSC0000003'
    },
    disabled() {
      // 요청자나 요청부서원일 경우 버튼실행 가능
      return this.licenseRequest.requestUserId !== this.$store.getters.user.userId 
        || this.licenseRequest.requestDeptCd !== this.$store.getters.user.deptCd
        || (this.licenseRequest.requestStepCd && this.licenseRequest.requestStepCd !== 'RSC0000001')
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
    window.addEventListener('resize', this.setSize);
  },
  mounted() {
    this.init();
  },
  deactivated() {
    window.removeEventListener('resize', this.setSize);
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.sop.lim.lincense.request.get.url;
      this.saveUrl = transactionConfig.sop.lim.lincense.request.insert.url;
      this.deleteUrl = transactionConfig.sop.lim.lincense.request.delete.url;
      this.receiptUrl = transactionConfig.sop.lim.lincense.request.reviewRecepit.url;
      // code setting
      this.setSize();
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.param.limLicenseRequestId) {
        this.$http.url = this.$format(this.detailUrl, this.param.limLicenseRequestId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.licenseRequest = _result.data;
        },);
      } else {
        this.licenseRequest.requestDeptCd = this.$store.getters.user.deptCd;  // 요청부서 코드
        this.licenseRequest.requestUserId = this.$store.getters.user.userId;  // 요청자
      }
    },
    setSize() {
      let height = window.innerHeight - 450;
      if (height < 300) {
        height = 300;
      }
      this.grid.height = String(height) + 'px'
    },
    dataChange(props) {
      if (props.row['editFlag'] !== 'C') {
        props.row['editFlag'] = 'U'
        props.row['chgUserId'] = this.$store.getters.user.userId
      }
    },
    addReview() {
      this.licenseRequest.reviews.push({
        limLicenseReviewId: uid(),  // 인허가 검토 일련번호
        limLicenseRequestId: this.param.limLicenseRequestId,  // 인허가 요청 일련번호
        limLicenseKindId: '',  // 인허가종류 알련번호
        renewalFlag: 'N',  // 갱신 여부, 갱신인 경우는 기존인허가번호 입력
        reviewDeptCd: this.$store.getters.user.deptCd,  // 검토부서
        reviewUserId: this.$store.getters.user.userId,  // 검토자
        reviewOpinion: '',  // 인허가 검토 의견
        reviewCompleteFlag: 'N',  // 검토완료 여부
        limLicenseKindStatusCd: 'LLKSC00001',  // 인허가종류별 검토상태
        asLicenseSeq: '',  // 기존 인허가 일련번호
        newLicenseSeq: '',  // 등록 인허가 일련번호
        regUserId: this.$store.getters.user.userId,  // 등록자
        editFlag: 'C'
      })
    },
    deleteReview() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '제외하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            if (!this.licenseRequest.deleteReviews) this.licenseRequest.deleteReviews = [];
            this.$_.forEach(selectData, item => {
              if (item.editFlag !== 'C'
                && this.$_.findIndex(this.licenseRequest.deleteReviews, { limLicenseReviewId: item.limLicenseReviewId }) === -1) {
                  this.licenseRequest.deleteReviews.push(item)
              }
              this.licenseRequest.reviews = this.$_.reject(this.licenseRequest.reviews, item);
            })
            this.$refs['table'].$refs['compo-table'].clearSelection();
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveData() {
      if (this.param.limLicenseRequestId) {
        this.saveUrl = transactionConfig.sop.lim.lincense.request.update.url;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.sop.lim.lincense.request.insert.url;
        this.mappingType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          if (this.$comm.validTable(this.grid.columns, this.licenseRequest.reviews, '인허가 검토', true)) {
            // 갱신을 하는 경우 갱신대상 인허가 필수
            if (this.licenseRequest.reviews && this.licenseRequest.reviews.length > 0) {
              if (this.$_.findIndex(this.licenseRequest.reviews, review => {
                return review.renewalFlag === 'Y'
                  && !review.asLicenseSeq
              }) > -1) {
                window.getApp.$emit('ALERT', {
                  title: '안내',
                  message: '갱신대상 인허가 정보가 입력되지 않은 행이 있습니다.',
                  type: 'warning', // success / info / warning / error
                });
                return;
              }
            }
            window.getApp.$emit('CONFIRM', {
              title: '확인', 
              message: '저장하시겠습니까?',
              
              type: 'info', // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {
                this.licenseRequest.regUserId = this.$store.getters.user.userId;
                this.licenseRequest.chgUserId = this.$store.getters.user.userId;

                this.isSave = !this.isSave;
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          }
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (this.mappingType === 'POST') {
        this.param.limLicenseRequestId = result.data.limLicenseRequestId
        this.$emit('emitStep', {
          name: 'setId',
          param: result.data.limLicenseRequestId
        })
      }
      this.getDetail();
    },
    requestData() {
      /**
       * 검토항목 하나 이상
       */
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          if (this.$comm.validTable(this.grid.columns, this.licenseRequest.reviews, '인허가 검토')) {
            window.getApp.$emit('CONFIRM', {
              title: '확인',
              message: '인허가요청을 하시겠습니까?',
              
              type: 'info', // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {
                this.licenseRequest.chgUserId = this.$store.getters.user.userId;

                this.isRequest = !this.isRequest;
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          }
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    requestCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
      this.$emit('emitStep', {
        name: 'currentStep',
        param: result.data.requestStepCd
      })
    },
    deleteData() {
      window.getApp.$emit('CONFIRM', {
        title: '확인', 
        message: '삭제하시겠습니까?',
        
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.param.limLicenseRequestId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            this.$emit('APP_REQUEST_SUCCESS');
            this.$emit('emitStep', {
              name: 'closePopup'
            })
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
  }
};
</script>
